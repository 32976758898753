import { Card, Col, Container, Row } from "react-bootstrap";

const Pricing = () => {
  return (
    <Container>
      <Row className="mb-5">
        <Col>
          <h2 className="mt-4">
            Pricing
          </h2>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Card className="m-1" style={{ height: "175px" }}>
            <Card.Body>
              <Card.Title>Basic</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Small Experiences</Card.Subtitle>
              <Card.Text>
                10 USD/month
              </Card.Text>
              <Card.Text>
                1-200 Roblox servers
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="m-1" style={{ height: "175px" }}>
            <Card.Body>
              <Card.Title>Premium</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Medium Experiences</Card.Subtitle>
              <Card.Text>
                20 USD/month
              </Card.Text>
              <Card.Text>
                201-1000 Roblox servers
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="m-1" style={{ height: "175px" }}>
            <Card.Body>
              <Card.Title>Professional</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Large Experiences</Card.Subtitle>
              <Card.Text>
                30 USD/month
              </Card.Text>
              <Card.Text>
                1001-infinite Roblox servers
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Pricing;
