import { Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

const markdown = `
# Documentation
***
### Table of Contents
[Installation](https://www.reblox.net/documentation)
***
`;

const Documentation = () => {
  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
        </Col>
      </Row>
    </Container>
  )
}

export default Documentation