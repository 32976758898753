import { Navbar, Container, Nav, Button, Form } from "react-bootstrap";
import Logo from "../media/logo_transparent.png";

const Header = () => {
  return (
    <Navbar bg="dark" expand="lg">
      <Container>
        <Navbar.Brand style={{ display: "flex", flexDirection: "row", alignItems: "center" }} href="/">
          <img
            alt=""
            src={Logo}
            width="200"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            {/* <Nav.Link href="documentation">Documentation</Nav.Link> */}
            <Nav.Link href="pricing">Pricing</Nav.Link>
            <Nav.Link href="contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Form className="d-flex">
          <Button as="a" href="https://app.reblox.net">Go to Dashboard</Button>
        </Form>
      </Container>
    </Navbar>
  )
}

export default Header;
