import { Navbar, Container, Nav } from "react-bootstrap";

const Footer = () => {
  return (
    <Navbar fixed="bottom">
      <Container>
        <Navbar.Brand href="/"></Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/terms">Terms of Service</Nav.Link>
          <Nav.Link href="/privacy">Privacy Policy</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Footer;
