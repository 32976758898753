import { Col, Container, Row, Image } from "react-bootstrap";
import Fade from 'react-bootstrap/Fade'
import GraphImage from "../media/graph.png";

const Home = () => {
  return (
    <Container>
      <Row className="mb-5">
        <Col>
          <h1 className="text-center mt-5">
            Professional Roblox Server Control
          </h1>
          <h3 className="text-center mt-3 text-muted">
            Reblox is a premium system for controlling Roblox experiences
          </h3>
        </Col>
      </Row>
      <Fade appear in={true}>
        <Row>
          <Col className="d-flex flex-column p-2">
            <h4 className="text-center">Reblox</h4>
            <p>
              The Reblox dashboard is a full featured, web based control panel. Reblox is a simple, light-weight, drag-and-drop plugin with minimal configuration, and maximum ability.
            </p>
            <Image rounded src={GraphImage} className="mt-3"/>
          </Col>
        </Row>
      </Fade>
    </Container>
  )
}

export default Home;
