import { Col, Container, Row, Button } from "react-bootstrap";

const Contact = () => {
  return (
    <Container>
      <Row className="mb-2">
        <Col>
          <h2 className="mt-4">
            Contact
          </h2>
          <Button as="a" href="mailto:reblox.support@gmail.com">
            Send us an Email
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button as="a" href="https://discord.gg/CYmAgThGGK">
            Join Our Discord!
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default Contact;
